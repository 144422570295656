import { graphql, useStaticQuery } from "gatsby";

export const useQuery = () =>
  useStaticQuery(graphql`
    query {
      clientLogo: file(relativePath: { eq: "interview/Unilever-middle.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      clientCover: file(relativePath: { eq: "interview/Unilever-top.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      clientLogoDark: file(
        relativePath: { eq: "Logos-client/Largeur variable/Dark/Unilever.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 250, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
