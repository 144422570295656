/* eslint-disable */
import React from "react";

export default {
  en: {
    quote: "the approach is effective",
    author: "Martine Proust, Tools and Hotline Manager",
    sector: "Consumer goods",
    interviewees: ["Martine Proust, Tools and Hotline Manager"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "Unilever",
        description: (
          <>
            <p>
              <a
                href="https://www.unilever.com/"
                style={{ textDecoration: "underline" }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Unilever
              </a>{" "}
              is one of the world's leading consumer-goods companies: the
              company produces and sells nearly 400 brands in more than 190
              countries. Every day, 2.5 billion people use Unilever products.
              Those are distributed to 25 million retailers, from supermarkets
              to small convenience stores, and through other high-growth
              channels such as e-commerce and direct-to-consumer sales.
            </p>
            <p>
              In daily business, their sales force travels all around France.
              And in order to make sure everything goes well, there must be
              tools, and a hotline! There relies the main responsibility of
              Martine Proust and her team.
            </p>
          </>
        ),
        img: "clientLogo",
      },
      {
        title: "Unilever & Jawg",
        description:
          "For Unilever, it is essential to ensure seller-satisfaction and management. Of course, such a structure relies on a strategic tool : their internal CRM, at the center of lots of everyday tasks.",
      },
      {
        title: "",
        description:
          "This is where Unilever and Jawg maps meet. The goal of this integration: to improve the user experience of the tool by allowing a quick view of critical information for specific stores on a map.",
      },
      {
        title: "",
        description: (
          <span>
            Recommended by one of Unilever's providers, Jawg maps and Unilever
            quickly started working together. For Martine Proust{" "}
            <q>the phase of contact was fast, cordial and effective</q>.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Part of the job was also geocoding around 8000 of Unilever customer
            stores. Martine Proust was glowing:{" "}
            <q>
              I had great feedback, I asked for advice and in the end I got
              exactly what I needed
            </q>
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            For Martine Proust, Jawg was already a good recommendation. Our
            understanding is she won’t hesitate to pass the word:{" "}
            <q>
              You are friendly, you know how to help, the approach is effective
              too. Yes, I would recommend Jawg
            </q>
            .
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Cordial, Efficient and Fast",
      },
    ],
  },
  fr: {
    quote: "l’approche est efficace",
    author: "Martine Proust, Responsable outils et hot-line",
    sector: "Produits de grande consommation",
    interviewees: ["Martine Proust, Responsable outils et hot-line"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "Unilever",
        description: (
          <>
            <p>
              <a
                href="https://www.unilever.fr/"
                style={{ textDecoration: "underline" }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Unilever
              </a>{" "}
              est l'un des leaders mondiaux sur le marché des produits de grande
              consommation : l'entreprise produit et vend près de 400 marques
              dans plus de 190 pays. Chaque jour, 2,5 milliards de personnes
              utilisent les produits Unilever. Leurs produits sont distribués à
              25 millions de détaillants, des supermarchés aux petits magasins
              de proximité, et à travers d'autres canaux à forte croissance tels
              que le commerce électronique et la vente directe au consommateur.
            </p>
            <p>
              Au quotidien, les forces de ventes sillonnent le territoire
              Français. Et pour que cela fonctionne bien, il faut des outils, et
              une hotline ! C'est la responsabilité principale de Martine Proust
              et de ses équipes.
            </p>
          </>
        ),
        img: "clientLogo",
      },
      {
        title: "Unilever & Jawg",
        description:
          "Veiller à la satisfaction des vendeurs et à leur encadrement est indispensable pour Unilever. Une telle structure utilise bien sur un CRM interne indispensable à la bonne gestion du quotidien.",
      },
      {
        title: "",
        description:
          "Et justement, c'est dans le cadre de cet outil stratégique que Jawg Maps et Unilever se sont trouvés. L’objectif de cette intégration : améliorer l’ergonomie de l’outil en permettant de visualiser les informations relatives à son parc de magasins via une carte.",
      },
      {
        title: "",
        description: (
          <span>
            C’est un des fournisseurs d’Unilever qui a mis Jawg en avant. Pour
            Martine Proust{" "}
            <q>
              &#160;La phase de contact a été rapide, cordiale et efficace&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Il s’agissait aussi de géocoder les quelques 8000 enseignes où
            interviennent les vendeurs. Martine Proust souligne :{" "}
            <q>
              &#160;j’ai eu un bon retour, j’ai demandé un conseil et finalement
              j’aurai un service&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Pour Martine Proust, Jawg est bonne recommandation de la part de son
            prestataire :{" "}
            <q>
              &#160;Vous êtes un partenaire convivial, vous savez rendre
              service, l’approche est efficace aussi, donc oui je recommanderais
              Jawg.&#160;
            </q>
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Cordial, efficace et rapide",
      },
    ],
  },
};
